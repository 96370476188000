import React from 'react';
import { Flex, SizedBox } from 'components/Grid';
import Menu from 'components/Menu';
import Image from 'components/Image';
import Icon from 'components/Icon';
import Toolbar from 'components/Toolbar';
import Link from 'components/Link';
import { withTheme } from 'styled-components';
import { menu } from 'utils/data';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSidebar } from 'state/modules/ui/platform';

const TopBar = ({ theme }) => {
  const dispatch = useDispatch();

  const { isMobile, sidebar } = useSelector(state => ({
    isMobile: state.ui.platform.mobile,
    sidebar: state.ui.platform.sidebar,
  }));

  const _didSetupMenu = () => {
    let style = null;

    if(isMobile) {
      style = {
        position: 'fixed',
        right: sidebar ? 0 : -300,
        top: 0,
        bottom:0,
        boxShadow: `0px 2px 5px ${theme.menu.sidebarShadow}`,
        width: 300,
        height: '100vh',
        backgroundColor: theme.menu.sidebar,
        zIndex: 999,
        transition: 'all 0.5s',
      };
    }

    return style;
  };

  const _onToggleSidebar= () => {
    dispatch(toggleSidebar(!sidebar));
  }; 

  return (
    <Toolbar>
      <Flex 
        align="center" 
        justify="space-between"
        height={`${theme.bar.height}px`}
      >
        <Link to="/">
          <Image 
            src={theme.logo.src}
            height={theme.logo.height}
            alt="Logo de Educatic"
          />
        </Link>
        {isMobile &&
          <SizedBox mr={2}>
            <Icon 
              name="bars"
              color={theme.colors.secondary}
              size="3em"
              onClick={_onToggleSidebar}
            />
          </SizedBox>
        }
        <SizedBox p="20px" style={_didSetupMenu()}>
          {isMobile &&
            <Flex justify="space-between">
              <Icon 
                name="close"
                color={theme.colors.secondary}
                size="3em"
                onClick={_onToggleSidebar}
              />
            </Flex>
          }
          <SizedBox mt={[5,5,0]}>
            <Menu 
              data={menu}
              mobile={isMobile}
              onSelectItem={_onToggleSidebar}
            />
          </SizedBox>
        </SizedBox>
      </Flex>
    </Toolbar>
  );
}

export default withTheme(TopBar);
