import React from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { Flex, SizedBox } from 'components/Grid';
import Text from 'components/Text';
import Link from 'components/Link';
import { withTheme } from 'styled-components';

const Menu = ({ data, mobile, theme, onSelectItem }) => {
  const { location } = useSelector((state) => state.router);

  const _onSelectItem = _ => {
    document.querySelector('html').scrollIntoView({
      behavior: 'smooth',
      offset: -70
    });

    if(onSelectItem) {
      onSelectItem();
    }
  }

  const _setColor = item => {
    let color = theme.colors.primary;

    if(location.pathname === item.link) {
      color = theme.colors.secondary;
    }

    return color;
  };

  return (
    <Flex 
      justify={mobile ? 'space-between' : 'flex-start'}
      direction={mobile ? 'column' : 'row'}
    >
      {data.map(item => (
        <Button
          key={item.id}
          align={mobile ? 'left' : 'center'}
          onClick={() => _onSelectItem(item)}
        >
          <Link to={item.link}>
            <Text color={_setColor(item)} size={`${mobile ? 2 : 1.5}em`}>
              {item.title}
            </Text>
          </Link>
        </Button>
      ))}
      <SizedBox ml={2} mt={[3,3,0]}>
        <Flex justify="flex-start">
          <Button background={theme.colors.primary}>
            <Link
              href={'https://aprende.educatic.com.co/'}>
              <Text
                color={theme.colors.text}
                size="1.5em"
                weight={600}
              >
                Ingresar
              </Text>
            </Link>
          </Button>
        </Flex>
      </SizedBox>
    </Flex>
  );
}

export default withTheme(Menu);
