import styled, { css } from 'styled-components';

const Image = styled.img`
  ${props => props.cover && css`
    background-size: cover;
    object-fit: cover;
  `}

  ${props => props.invert && css`
    filter: invert(70%); 
  `}

  ${props => props.dynamic && css`
    width: 100%;
  `}
`;

export default Image;
