import React from 'react';
import Bot from 'components/Bot/Bot';
import { steps } from 'utils/data';

const ChatBot = () => {
  return (
    <Bot steps={steps} />
  );
};

export default ChatBot;
