import styled from 'styled-components';

const Separator = styled.div`
  width: ${props => props.width};
  height: 1px;
  margin: 0 auto;
  display: block;
  border-top: 1px solid rgb(140, 139, 139);
  background-color: ${props => props.theme.colors.primary}; 
`;

export default Separator;
