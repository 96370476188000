import general from './general';

export default Object.assign({}, general, {
  name: 'light',
  bar: {
    height: 120,
    background: 'white',
    color: '#000',
    shadow: '1px 1px 3px black',
  },
  header: {
    height: '480px',
  },
  benefits: {
    height: '260px',
  },
  logo: {
    src: '/assets/images/logo.png',
    height: 80,
  },
  menu: {
    sidebarShadow: '#333',
    sidebar: 'white',
  },
  colors: {
    primary: '#03CFFF',
    secondary: '#00608C',
    dimmed: '#8799a7',
    icon: '#72bfff',
    text: 'white',
    product: '#3EB3E3',
    product_description: '#575757',
    generic: '#a7a7a7',
    benefits: '#BFE0EA',
    benefitsBorder: '#99cddd',
    prefooter: '#eee',
    cta: '#FEFBF6',
    storesBg: '#FEFBF6',
    currentProduct: '#4169E1',
    lightGray: '#eee',
    bgAlt: '#FEFBF6',
    generic_white: '#fff',
    danger: '#f00',
    success: '#358900',
  },
  shadow: '0px 0px 1px gray',
  fontColor: 'black',
  bgColor: 'white',

  //bot
  bot: {
    background: '#03CFFF',
    fontFamily: 'Helvetica Neue',
    headerBgColor: 'rgb(62, 179, 227)',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: 'rgb(62, 179, 227)',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
  }
});
