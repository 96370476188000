import React, { useCallback, useEffect } from 'react';
import Layout from '../Layout';

import Routes from 'routes';
import { ThemeProvider } from 'styled-components';
import THEME from 'appearance/themes';

import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'utils/platform';
import { setPlatform } from 'state/modules/ui/platform';
import { ConnectedRouter } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import getMetatags from 'utils/seo';
import history from 'state/history';

function App() {
  const dispatch = useDispatch();

  const stateToProps = state => ({
    mode: state.ui.mode.theme,
  });

  const { mode } = useSelector(stateToProps);

  /**
   * listen platform changes
   * @param {Object} changed
   * @returns {Void}
   */
  const _onQueryChanged = useCallback(e => {
    dispatch(setPlatform(e.matches));
  }, [dispatch]);

  /**
   * handle he platform changes
   */
  useEffect(() => {
    const query = isMobile(THEME[mode].breakpoints[1]);
    query.addListener(_onQueryChanged);

    _onQueryChanged(query);
  }, [mode, _onQueryChanged]);

  return (
    <ThemeProvider theme={THEME[mode]}>
      <ConnectedRouter history={history}>
        <Layout>
          <Helmet>{getMetatags()}</Helmet>
          <Routes />
        </Layout>
      </ConnectedRouter>
    </ThemeProvider>
  );
}

export default App;
