import styled from 'styled-components';
import PropTypes from 'prop-types';

const Standard = styled.button.attrs(props => ({ 
  type: props.type, 
}))`
  padding: 10px;
  border-radius: 5px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  border: none;
  cursor: pointer;
  text-align: ${props => props.align};
  transition: background 250ms ease-in-out, transform 150ms ease;
  outline: 0;
  background-color: ${props => props.background};
  color: ${props => props.color};
`;

Standard.propTypes = {
  background: PropTypes.string,
  align: PropTypes.string,
  type: PropTypes.string,
};

Standard.defaultProps = {
  background: 'transparent',
  align: 'center',
  type: 'button',
};

export default Standard;
