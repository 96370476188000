import React from 'react';
import Mobile from './StoresMobile';
import Desktop from './StoresDesktop';
import { useSelector } from 'react-redux';

const Stores = () => {
  const mobile = useSelector(state => state.ui.platform.mobile); 
  return mobile ? <Mobile /> : <Desktop />; 
}

export default Stores;
