import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ui from './modules/ui';
import general from './modules/general';

export default history => combineReducers({
  router: connectRouter(history),
  ui,
  general,
});
