/**
 * action types
 */
export const PLATFORM_REQUEST = 'PLATFORM_REQUEST';
export const PLATFORM_RECEIVE = 'PLATFORM_RECEIVE';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

const defaultState = {
  mobile: false,
  sidebar: false,
};

/**
 * platform reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default function reducer(state = defaultState, action = {}) {
  switch(action.type) {
    case PLATFORM_REQUEST:
      return {
        ...state,
      };
    case PLATFORM_RECEIVE:
      return {
        ...state,
        mobile: action.mobile,
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: action.sidebar,
      };
    default:
      return state;
  }
}

/**
 * request platform
 * @returns {Object}
 */
export function requestPlatform() {
  return { type: PLATFORM_REQUEST };
}

/**
 * receive platform mobile/desktop
 * @param {Boolean} mobile
 * @returns {Object}
 */
export function receivePlatform(mobile) {
  return { type: PLATFORM_RECEIVE, mobile };
}

/**
 * set platform
 * @param {Boolean} value
 * @returns {Promise}
 */
export function setPlatform(value) {
  return dispatch => {
    dispatch(receivePlatform(value));
  }
}

/**
 * toggle sidebar
 * @param {Boolean} sidebar
 * @returns {Promise}
 */
export function toggleSidebar(sidebar) {
  return { type: TOGGLE_SIDEBAR, sidebar };
}
