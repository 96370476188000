import React from 'react';
import { SizedBox, Flex, Box } from 'components/Grid';
import Image from 'components/Image';
import Title from 'components/Title';
import Text from 'components/Text';
import Link from 'components/Link';
import { withTheme } from 'styled-components';

const Mobile = ({ theme }) => {
  return (
    <SizedBox 
      background={theme.colors.storesBg}
      p={4}
    >
      <SizedBox mb={4}>
        <Title 
          text="Descarga nuestra aplicación" 
        />
      </SizedBox>
      <Box>
        <SizedBox>
          <Flex align="center" direction="column">
            <Image 
              src="/assets/images/phones.png" 
              width='320px'
              height="340px"
              alt="versión móvil de educatic para android y iOS"
            />
          </Flex>
          <SizedBox mt={2}>
            <Text 
              color={theme.colors.primary}
              type="h3"
            >
              Disfruta desde la comodidad de tu celular nuestra plataforma
            </Text>
            <SizedBox mt={2}>
              <Text 
                type="p"
                color={theme.colors.generic}
              >
                Nuestro sistema puede ser consultado desde cualquier celular, así mismo es una herramienta de comunicación para toda la comunidad.
              </Text>
            </SizedBox>
            <SizedBox mt={4}>
              <Flex justify="center">
                <Link href="https://play.google.com/store/apps/details?id=co.com.educatic.educaticapp">
                  <Image 
                    src="/assets/images/gp.png"
                    height="50px"
                    alt="Botón para descargar la versión Android de Educatic"
                  />
                </Link>
                <SizedBox ml={4}>
                  <Link href="https://apps.apple.com/us/app/educatic/id1434464769?ls=1">
                    <Image 
                      src="/assets/images/ap.png"
                      height="50px"
                      alt="Botón para descargar la versión iOS de Educatic"
                    />
                  </Link>
                </SizedBox>
              </Flex>
            </SizedBox>
          </SizedBox>
        </SizedBox>
      </Box>
    </SizedBox>
  );
}

export default withTheme(Mobile);
