import React from 'react';
import { SizedBox, Flex } from 'components/Grid';
import Text from 'components/Text';
import Link from 'components/Link';
import Icon from 'components/Icon';
import { withTheme } from 'styled-components';

const Social = ({ theme }) => {
  return (
    <SizedBox py={4}>
      <Flex 
        justify="center" 
        align="center"
      >
        <Text 
          mr="15px"
          color={theme.colors.generic}
        >
          Conéctate con nuestras redes sociales
        </Text>
        <Flex>
          <SizedBox>
            <Link href="https://www.linkedin.com/company/educatic-s.a.s/about/">
              <Icon 
                name="linkedin" 
                size="2em"
                pointer
              />
            </Link>
          </SizedBox>
          <SizedBox ml="10px">
            <Link href="https://www.youtube.com/channel/UCwk747tvNLNs-2ip0ZnIDmQ">
              <Icon 
                name="youtube" 
                size="2em"
                pointer
              />
            </Link>
          </SizedBox>
          <SizedBox ml="10px">
            <Link href="#">
              <Icon 
                name="twitter" 
                size="2em"
                pointer
              />
            </Link>
          </SizedBox>
        </Flex>
      </Flex>
    </SizedBox>
  );
}

export default withTheme(Social);
