import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import history from './history';
import thunk from 'redux-thunk';
import { APP_NAME } from 'utils/constants';
import reducer from './reducer';

const middleware = applyMiddleware(
  thunk,
  routerMiddleware(history),
);

const devTool = composeWithDevTools({ name: APP_NAME });
const enhancer = process.env.NODE_ENV !== 'production'
  ? devTool(middleware)
  : middleware;

const store = createStore(
  reducer(history), 
  {}, 
  compose (enhancer),
);

export default store;
