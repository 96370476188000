import React from 'react';
import { SizedBox, Flex } from 'components/Grid';
import Image from 'components/Image';
import Title from 'components/Title';
import Card, { CardContent } from 'components/Card';
import Text from 'components/Text';
import Link from 'components/Link';
import { withTheme } from 'styled-components';

const Desktop = ({ theme }) => {
  return (
    <SizedBox 
      background={theme.colors.storesBg}
      p={4}
    >
      <SizedBox mb={`70px`}>
        <Title 
          text="Descarga nuestra aplicación" 
        />
      </SizedBox>
      <Flex justify="center">
        <Card 
          background="white"
          shadow="3px 3px 10px lightgray"
          py={[3,3,5]}
          pl={[3,3,5]}
          pr={[3,3,0]}
        >
          <CardContent>
            <Flex justify="space-between">
              <SizedBox width={1/3}>
                <Text 
                  color={theme.colors.primary}
                  type="h3"
                >
                  Disfruta desde la comodidad de tu celular nuestra plataforma
                </Text>
                <SizedBox mt={3} mb={4}>
                  <Text 
                    type="p"
                    color={theme.colors.generic}
                  >
                    Nuestro sistema puede ser consultado desde cualquier celular, así mismo es una herramienta de comunicación para toda la comunidad.
                  </Text>
                </SizedBox>
                <SizedBox>
                  <Flex>
                    <SizedBox mr={3} >
                      <Link href="https://play.google.com/store/apps/details?id=co.com.educatic.educaticapp">
                        <Image 
                          src="/assets/images/gp.png"
                          height="50px"
                          alt="Botón para descargar la versión Android de Educatic"
                        />
                      </Link>
                    </SizedBox>
                    <SizedBox>
                      <Link href="https://play.google.com/store/apps/details?id=co.com.educatic.educaticapp">
                        <Image 
                          src="/assets/images/ap.png"
                          height="50px"
                          alt="Botón para descargar la versión iOS de Educatic"
                        />
                      </Link>
                    </SizedBox>
                  </Flex>
                </SizedBox>
              </SizedBox>
              <SizedBox position="relative">
                <SizedBox 
                  position="absolute"
                  style={{ 
                    zIndex: 2, 
                    right: 0, 
                    top: -135 
                    }}
                  >
                  <Image 
                    src="/assets/images/phones.png" 
                    width="500px"
                    height="530px"
                    alt="versión móvil de Educatic para Android y iOS"
                  />
                </SizedBox>
              </SizedBox>
            </Flex>
          </CardContent>
        </Card>
      </Flex>
    </SizedBox>
  );
}

export default withTheme(Desktop);
