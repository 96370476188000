import React from 'react';
import { render } from 'react-dom';
import App from 'components/App';
import * as serviceWorker from 'serviceWorker';
import { Provider } from 'react-redux';
import store from 'state/store';
import 'index.css';

const APP = (
  <Provider store={store}>
    <App />
  </Provider>
); 
const TAG = document.getElementById('root');
render(APP, TAG);

serviceWorker.unregister();
