import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import async from 'components/Async';

const Home = async(() => import('pages/Home').then(module => module.default));
const About = async(() => import('pages/About').then(module => module.default));
const Contact = async(() => import('pages/Contact').then(module => module.default));
const NotFound = async(() => import('pages/404').then(module => module.default));

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/nosotros' component={About} />
      <Route path='/contacto' component={Contact} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
} 

export default withRouter(Routes);
