import React from 'react';
import styled from 'styled-components';
import { Flex } from 'components/Grid';
import PropTypes from 'prop-types';

const CardFooter = styled(({
  border,
  ...others
}) => <Flex {...others} />).attrs(props => ({
  width: 1,
}))`
  ${props => props.border && `border-top: 1px solid #eee;`}
  max-height: ${props => props.theme.spaces[4]}px;
`;

CardFooter.propTypes = {
  border: PropTypes.bool,
};

export default CardFooter;
