import React from 'react';
import { Flex } from 'components/Grid';
import Text from 'components/Text';
import { withTheme } from 'styled-components';

const Title = ({ theme, fontSize, text }) => {
  return (
    <Flex justify="center">
      <Text 
        color={theme.colors.secondary}
        size={fontSize}
        weight={700}
        align="center"
      >
        {text}
      </Text>
    </Flex>
  );
}

Title.defaultProps = {
  fontSize: '1.5em',
};

export default withTheme(Title);
