import styled from 'styled-components';
import PropTypes from 'prop-types';

const Icon = styled.i.attrs(props => ({
  className: `fa fa-${props.name}`,
}))`
  font-size: ${props => props.size};
  color: ${props => props.color};
  cursor: ${props => props.pointer ? 'pointer' : 'default'};
`;

Icon.displayName = 'Icon';

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  pointer: PropTypes.bool,
};

Icon.defaultProps = {
  size: '1em',
  color: '#333',
};

export default Icon;
