import React from 'react';
import Container from 'tiny-slider-react';

const Carousel = ({ config, children }) => {
	const settings = {
		lazyload: true,
		nav: false,
		mouseDrag: true,
		loop: true,
		items: 1,
		gutter: 5,
		autoplay: true,
		controls: false,
		autoplayHoverPause: true,
		autoplayButtonOutput: false,
    autoplayTimeout: 10000,
    speed: 370,
  };
  
  const conf = Object.assign({}, settings, config);

  return (
    <Container settings={conf}>
      {children}
    </Container>
  );
}

export default Carousel;
