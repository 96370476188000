import styled from 'styled-components';
import { Flex as _Flex } from 'grid-styled';

const Flex = styled(_Flex).attrs(props => ({
  justifyContent: props.justify,
  alignItems: props.align,
  flexWrap: props.wrap,
  flexDirection: props.direction
}))`
  height: ${props => props.height};
  background: ${props => props.background};
`

export default Flex;
