import React from 'react';
import SimpleBot from 'bongga-react-simple-chatbot';
import Container from './Container';
import Text from 'components/Text';
import { SizedBox } from 'components/Grid';
import THEME from 'appearance/themes';
import { withTheme, ThemeProvider } from 'styled-components';

const Bot = ({ steps, theme }) => {
  if(!steps || steps.length === 0) return null;

  return (
    <Container justify="center" direction="column">
      <ThemeProvider theme={THEME['light'].bot}>
        <SimpleBot 
          headerTitle="Sarah"
          steps={steps}
          floating
          floatingIcon="/assets/images/avatar.svg"
        />
      </ThemeProvider>
      <SizedBox mt={1}>
        <Text
          size="0.8em"
          color={theme.colors.secondary}
        >
          Escribenos
        </Text>
      </SizedBox>
    </Container>
  )
}

export default withTheme(Bot);
