import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, SizedBox } from 'components/Grid';
import Text from 'components/Text';
import Link from 'components/Link';
import Image from 'components/Image';
import { withTheme } from 'styled-components';

const Footer = ({ theme }) => {
  const isMobile = useSelector(state => state.ui.platform.mobile);

  return (
    <Flex
      is="footer"
      align="center"
      justify="center"
      direction="column"
      background={`white`}
      style={{ 
        borderTop: `5px solid ${theme.colors.secondary}`,
        position: 'relative',
      }} 
    >
      <SizedBox 
        p={[0,0,4]}
        my={[3,3,0]}
        mx={[5,5,0]}
      >
        <Text
          size="0.7em"
          color={theme.colors.generic}
          align="center"
        >
          {`© Copyright ${new Date().getFullYear()} — Todos los derechos reservados | `}
          <Link to="/"><Text>Políticas de privacidad</Text></Link>&nbsp;y&nbsp;
          <Link to="/"><Text>Habeas data</Text></Link>
        </Text>
      </SizedBox>
      <SizedBox 
        style={isMobile ? null : { position: 'absolute', right: 20, top: -5 }}
        my={[3,3,0]}
        p={[0,0,4]}
      >
        <Image 
          src="/assets/images/logo_footer.png" 
          alt="desarrollado por Educatic"
        />
      </SizedBox>
    </Flex>
  );
}

export default withTheme(Footer);
