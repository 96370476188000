import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Flex } from 'components/Grid';

const CardHeader = styled(({ border, ...props }) => <Flex {...props} />).attrs(props => ({
  justify: props.justify,
  align: 'center',
  width: 1,
  p: [2,2,3]
}))`
  max-height: ${props => props.theme.spaces[4]}px;

  ${props => props.border && `
    border-bottom: : 1px solid #eee
  `};
`;

CardHeader.propTypes = {
  border: PropTypes.bool,
}

CardHeader.defaultProps = {
  border: true,
}

export default CardHeader;
