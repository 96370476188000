/**
 * convert px to em
 * @param  {Number|String} value
 * @return {String}
 */
export function em(value) {
  let val = value;
  if (typeof value === 'string' && value.indexOf('px') > -1) {
    val = parseInt(value.split('px')[0], 10);
  }

  val /= 16;

  return `${val}em`;
}

/**
 * convert em to px
 * @param  {Number|String} value
 * @return {String}
 */
export function px(value) {
  let val = value;
  if (typeof value === 'string' && value.indexOf('em') > -1) {
    val = parseInt(value.split('em')[0], 10);
  }

  val *= 16;

  return `${val}px`;
}

/**
 * converts from hex to rgba
 * @param {String} hex
 * @param {Number} alpha
 * @returns {String}
 */
export function hexToRGBA(hex, alpha){
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')}, ${alpha})`;
  }
  throw new Error('Bad Hex');
}
