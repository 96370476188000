import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import PreFooter from 'components/PreFooter';
import Stores from 'components/Stores';
import Clients from 'components/Clients';
import Social from 'components/Social';
import ChatBot from 'components/Bot';
import TopBar from 'components/TopBar';

import Separator from 'components/Separator';
import Fade from 'components/Fade';
import { SizedBox } from 'components/Grid';

function Layout({ children }) {
  return (
    <>
      <TopBar />
      <Header />
      <Fade>
        {children}
      </Fade>
      <SizedBox my={4}>
        <Separator width="100px" />
      </SizedBox>
      <Clients />
      <SizedBox my={4}>
        <Separator width="100px" />
      </SizedBox>
      <Stores />
      <Social />
      <PreFooter />
      <Footer />
      <ChatBot />
    </>
  );
}

export default Layout;
