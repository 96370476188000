import styled from 'styled-components';
import { SizedBox } from 'components/Grid';

const CardContent = styled(SizedBox).attrs(props => ({
  width: 1,
  p: [2,2,3]
}))`

`;

export default CardContent;
