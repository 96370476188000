import styled from 'styled-components';
import { Box as _Box } from 'grid-styled';

const Box = styled(_Box)`
  height: ${props => props.height};
  background: ${props => props.background};
  position: ${props => props.position};
`

export default Box;
