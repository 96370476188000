/**
 * check if a valid url
 * @param {String} url
 * @return {Boolean}
 */
export function isUrl(url) {
  return url.includes('http')
}

/**
 * isUrl
 * @desc This URL regex will validate most common URL formats correctly.
 * @param {String} value
 * @returns {Boolean}
 */
export function isValidUrl(value) {
  let re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g;
  return re.test(value);
}

/**
 * isAlphaNumeric
 * @param {String} value
 * @desc Test for alpha-numeric characters with this regexp.
 * @returns {Boolean}
 */
export function isAlphaNumeric(value) {
  let re = /^[a-zA-Z0-9]*$/gim;
  return re.test(value);
}

/**
 * isAlpha
 * @desc This regex will test for alphabetic characters only (upper and lowercase).
 * @param {String} value
 * @returns {Boolean}
 */
export function isAlpha(value) {
  let re = /^[a-zA-Z]*$/gim;
  return re.test(value);
}

/**
 * isValidEmail
 * @desc This email regex is not fully RFC5322-compliant, but it will validate
 *       most common email address formats correctly.
 * @param {String} email
 * @returns {Boolean}
 */
export function isValidEmail(email) {
  let re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;
  return re.test(email);
}

/**
 * check if text is empty
 * @param {String} text
 */
export function empty(text) {
  if(!text || typeof text !== 'string') return true
  return text.trim().length === 0
}

/**
 * check if string is a valid number
 * @param {String} numberString
 * @returns {Boolean}
 */
export function isNumber(numberString) {
  const regex = /^([0-9]+)$/
  return regex.test(numberString)
}
