import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SizedBox as Box } from 'components/Grid';

const Text = styled(({
  type,
  margin,
  padding,
  size,
  weight,
  font,
  styl,
  color,
  pointer,
  selectable,
  decor,
  lineheight,
  shadow,
  align,
  ellipsis,
  width,
  ...rest,
}) => <Box {...rest} />).attrs(props => ({
  is: props.type,
  m: props.margin,
  p: props.padding,
}))`
  width: ${props => props.width};
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  font-family: ${props => props.font};
  font-style: ${props => props.styl};
  color: ${props => props.color};
  cursor: ${props => props.pointer};
  user-select: ${props => props.selectable};
  text-decoration: ${props => props.decor};
  line-height: ${props => props.lineheight};
  text-shadow: ${props => props.shadow};
  text-align: ${props => props.align};
  vertical-align: ${props => props.vertical};
  text-transform: ${props => props.transform};
  letter-spacing: ${props => props.spacing};
  ${props => props.ellipsis && `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `};
`;

Text.propTypes = {
  width: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.number,
  font: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  styl: PropTypes.string,
  vertical: PropTypes.string,
  margin: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
    PropTypes.string,
  ]),
  padding: PropTypes.string,
  pointer: PropTypes.string,
  selectable: PropTypes.string,
  decor: PropTypes.string,
  lineheight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  shadow: PropTypes.string,
  align: PropTypes.string,
  transform: PropTypes.string,
  spacing: PropTypes.string,
};

Text.defaultProps = {
  type: 'span',
  margin: 0,
  padding: '0px',
  align: 'left',
};

export default Text;
