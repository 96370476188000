import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Fade from 'components/Fade';
import Image from 'components/Image';
import Carousel from 'components/Carousel';
import { SizedBox } from 'components/Grid';
import Container from './Container';
import { withTheme } from 'styled-components';
import { header } from 'utils/data';

const Header = ({ theme }) => {
  const [shadow, setShadow] = useState(false);

  const { location, mobile } = useSelector((state) => ({
    location: state.router.location,
    mobile: state.ui.platform.mobile,
  }));

  const _handleScroll = () => {
    const reference = (theme.bar.height - (theme.bar.height * .9));

    const scrollTop = (
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0
    );

    setShadow(scrollTop > reference);
  };

  useEffect(() => {
    document.addEventListener('scroll', _handleScroll);

    return () => {
      document.removeEventListener('scroll', _handleScroll);
    };
  });

  const data = header[location.pathname];

  if(!data) return null;

  const random = Math.floor(Math.random() * data.images.length);

  return (
    <Container hasShadow={shadow}>
      <SizedBox mt={theme.bar.height}>
        {(mobile && data.images.length > 0) &&
          <Fade>
            <Image
              src={data.images[random].src_mobile}
              dynamic
              height={theme.header.height}
              cover
              alt={data.images[random].alt}
            /> 
          </Fade>
        }
        {!mobile &&
        <Carousel config={{
          items: 1,
          autoplayTimeout: 5000,
          arrowKeys: true,
        }}
        >
          {data && data.images.map((item, i) => (
            <Fade key={i}>
              <Image
                className={`tns-lazy-img`}
                data-src={mobile ? item.src_mobile : item.src}
                src={mobile ? item.src_mobile : item.src}
                dynamic
                height={theme.header.height}
                cover
                alt={item.alt}
              />
            </Fade>
          ))}
        </Carousel>
        }
      </SizedBox>
    </Container>
  );
}

export default withTheme(Header);
