import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SizedBox } from 'components/Grid';

const Card = styled(({
  fill,
  pointer,
  opacity,
  shadow,
  image,
  ...rest
}) => <SizedBox {...rest} />).attrs(props => ({
  is: props.type,
}))`
  background: ${props => props.background || props.theme.colors.white};
  box-shadow: ${props => props.shadow || props.theme.shadow};
  border-radius: ${props => props.radius};
  height: ${props => props.fill ? '100%' : props.height};
  width: ${props => props.width};
  cursor: ${props => props.pointer ? 'pointer' : 'default'};
  opacity: ${props => props.opacity};

  ${props => props.image && `
    background-image: url(${props.image});
    background-size: cover;
    background-repeat: no-repeat;
  `}
`;

Card.propTypes = {
  background: PropTypes.string,
  radius: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  pointer: PropTypes.bool,
  opacity: PropTypes.number,
  shadow: PropTypes.string,
}

Card.defaultprops = {
  height: 'auto',
}

export default Card;
