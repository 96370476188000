import React from 'react';
import { SizedBox, Flex } from 'components/Grid';
import Carousel from 'components/Carousel';
import Image from 'components/Image/Lazy';
import Title from 'components/Title';
import { clients } from 'utils/data';

const Clients = () => {
  return (
    <SizedBox>
      <SizedBox mb={4}>
        <Title text="Algunos de nuestros clientes" />
      </SizedBox>
      <Carousel config={{
          items: 4,
          autoplayTimeout: 5000,
          arrowKeys: true,
          responsive: {
            0: {
              items: 1,
            },
            992: {
              items: 2
            },
            1200: {
              items: 4
            },
          },
        }}
      >
        {clients.map(item => (
          <Flex 
            key={item.id} 
            justify="space-around"
            align="center"
            height="150px"
            style={{ display: 'inline-flex' }}
          >
            <Flex align="center">
              <Image
                className={`tns-lazy-img`}
                data-src={item.image}
                width={200}
                alt={item.alt}
              />
            </Flex>
          </Flex>
        ))}
      </Carousel>
    </SizedBox>
  );
}

export default Clients;
