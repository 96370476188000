import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { isUrl } from 'utils/validations';

const Link = ({ children, ...props }) => {
  let Comp = null;

  if(props.href && isUrl(props.href)) {
    Comp = (
      <a
        target="_blank"
        rel="noopener noreferrer" 
        {...props}
      >
        {children}
      </a>
    );
  } else if(props.to) {
    Comp = (
      <RouterLink {...props}>{children}</RouterLink>
    );
  } else if(props.dest) {
    Comp = (
      <a href={`#${props.dest}`}>
        <div>{children}</div>
      </a>
    );
  }

  return Comp;
}

export default Link;
