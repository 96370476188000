import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from 'components/Grid';

const Toolbar = styled(({
    border,
    shadow,
    position,
    ...props
}) => <Box {...props} screen="full"/>).attrs(props => ({
  width: 1,
  p: 3,
}))`
  background: ${props => props.theme.bar.background};
  border-bottom: 1px solid #eee;
  box-shadow: ${props => props.shadow ? props.theme.bar.shadow : 'none'};
  position: fixed;
  top: 0;
  z-index: 9;
  padding: 0 ${props => props.theme.spaces[5]}px;

  @media screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    padding: 0 ${props => props.theme.spaces[1]}px;
  }
`;

Toolbar.displayName = 'Toolbar';
Toolbar.propTypes = {
  border: PropTypes.bool,
  shadow: PropTypes.bool,
  background: PropTypes.string,
};

Toolbar.defaultProps = {
  background: 'green',
};

export default Toolbar;
