import React from 'react';

export default function getMetatags(meta) {
  const tags = (
    <tags>
      <link rel="canonical" href="http://educatic.com.co" />
      <title>Educatic | Cambiando la manera de aprender</title>

      <meta name="description" content="EDUCATIC es un software Saas que gestiona en la nube todos los procesos administrativos, académicos, financieros, mercadeo y sociales que requieren las instituciones educativas para reducir el tiempo de atención a los estudiantes y aumentar la eficiencia en todos los procesos. Además tiene una herramientas de visualización y predicción" />
      <meta name="keywords" content="Plataforma Educativa,Gerencia del Conocimiento,E-learning,Conocimiento,TIC,Plataforma en Gerencia de Proyectos,Control Presupuestal,Inteligencia de Negocio,Software Educativo,Software Académico, Gestión Académica, Gestión Administrativa, Gestión Educatica, Software académico, Sistema académico, Plataforma académica, Solución académica, Registro de notas, Software académico en la nube" />
      <meta name="author" content="Educatic S.A.S" />
      <meta name="image" content="/assets/images/banner.png" />

      {/* Item prop */}
      <meta itemprop="name" content="Educatic | Cambiando la manera de aprender" />
      <meta itemprop="description" content="EDUCATIC es un software Saas que gestiona en la nube todos los procesos administrativos, académicos, financieros, mercadeo y sociales que requieren las instituciones educativas para reducir el tiempo de atención a los estudiantes y aumentar la eficiencia en todos los procesos. Además tiene una herramientas de visualización y predicción" />
      <meta itemprop="image" content="/assets/images/banner.png" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Educatic | Cambiando la manera de aprender" />
      <meta name="twitter:description" content="EDUCATIC es un software Saas que gestiona en la nube todos los procesos administrativos, académicos, financieros, mercadeo y sociales que requieren las instituciones educativas para reducir el tiempo de atención a los estudiantes y aumentar la eficiencia en todos los procesos. Además tiene una herramientas de visualización y predicción" />
      <meta name="twitter:site" content="@educatic" />
      <meta name="twitter:creator" content="@educatic" />
      <meta name="twitter:image:src" content="/assets/images/banner.png" />

      {/* Facebook */}
      <meta property="og:title" content="Educatic | Cambiando la manera de aprender" />
      <meta property="og:description" content="EDUCATIC es un software Saas que gestiona en la nube todos los procesos administrativos, académicos, financieros, mercadeo y sociales que requieren las instituciones educativas para reducir el tiempo de atención a los estudiantes y aumentar la eficiencia en todos los procesos. Además tiene una herramientas de visualización y predicción" />
      <meta property="og:image" content="https://twizzy.app/social.jpg" />
      <meta property="og:url" content="https://educatic.com.co" />
      <meta property="og:site_name" content="Educatic | Cambiando la manera de aprender" />
      <meta property="og:type" content="website" />
      <meta name="robots" content="all" />
    </tags>
  );

  return tags.props.children;
}
