import styled from 'styled-components';

const Header = styled.header`
  background-color: ${props => props.theme.bar.background};
  transition: all 500ms ease;
  height: ${props => props.theme.header.height};
  position: relative;
`;

export default Header;
