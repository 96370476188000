import React from 'react';
import { useSelector } from 'react-redux';
import { SizedBox, Flex } from 'components/Grid';
import Text from 'components/Text';
import { withTheme } from 'styled-components';

const Stores = ({ theme }) => {
  const isMobile = useSelector(state => state.ui.platform.mobile);

  return (
    <SizedBox 
      style={{ borderTop: `3px solid ${theme.colors.primary}`}} 
      background={theme.colors.prefooter}
      p={4}
    >
      <Flex 
        direction={isMobile ? 'column' : 'row'}
        justify={isMobile ? 'center' : 'space-around'}
      >
        <SizedBox>
          <Text 
            type="p"
            color={theme.colors.secondary}
            weight={700}
            lineheight="30px"
          >
            Oficina:
          </Text>
          <Text type="p" lineheight="22px">Carrera 42 No 5 SUR 145</Text>
          <Text type="p" lineheight="22px">Piso 13, oficina 125</Text>
          <Text type="p" lineheight="22px">WeWork, Medellín, Antioquia</Text>
          <Text type="p" lineheight="22px">Celular: (+57) 311 634 45 26</Text>
          <Text type="p" lineheight="22px">
            Email: walter.alvarez@educatic.com.co
          </Text>
        </SizedBox>
        <SizedBox mt={[3,3,0]}>
          <Text 
            type="p"
            color={theme.colors.secondary}
            weight={700}
            lineheight="30px"
          >
            Servicio y Soporte:
          </Text>
          <Text type="p" lineheight="22px">soporte@educatic.com.co</Text>
          <Text type="p" lineheight="22px">(+57) 311 634 45 26</Text>
        </SizedBox>
      </Flex>
    </SizedBox>
  );
}

export default withTheme(Stores);
