export const products =
  {
    "4":[
      {
        "id":0,
        "idTema":1850,
        "idAsignatura":4,
        "strTema":"App movil",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Su IE al alcance de la mano.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Comunicacion2020.09.14.10.17.37.png",
        "strImagenMouseOver":"Comu2020.09.14.10.17.37.png",
        "strAsignatura":"COMUNICACIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":21868,
        "idAsignatura":4,
        "strTema":"Buzones",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Defina diferentes medios de comunicación con sus diversos segmentos de usurios.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Comunicacion2020.09.14.10.17.37.png",
        "strImagenMouseOver":"Comu2020.09.14.10.17.37.png",
        "strAsignatura":"COMUNICACIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31902,
        "idAsignatura":4,
        "strTema":"e-commerce",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Multiples esquemas de recuado electronico.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Comunicacion2020.09.14.10.17.37.png",
        "strImagenMouseOver":"Comu2020.09.14.10.17.37.png",
        "strAsignatura":"COMUNICACIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":1851,
        "idAsignatura":4,
        "strTema":"Encuestas",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Conozca su comunidad de forma sencilla.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Comunicacion2020.09.14.10.17.37.png",
        "strImagenMouseOver":"Comu2020.09.14.10.17.37.png",
        "strAsignatura":"COMUNICACIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31893,
        "idAsignatura":4,
        "strTema":"Formularios dinamicos / paginas landing",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Capture información y defina flujos de trabajo simples.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Comunicacion2020.09.14.10.17.37.png",
        "strImagenMouseOver":"Comu2020.09.14.10.17.37.png",
        "strAsignatura":"COMUNICACIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":1835,
        "idAsignatura":4,
        "strTema":"Gestion de prestamos y bivi / multas",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Renovación de prestamos bibliotecarios.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Comunicacion2020.09.14.10.17.37.png",
        "strImagenMouseOver":"Comu2020.09.14.10.17.37.png",
        "strAsignatura":"COMUNICACIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31890,
        "idAsignatura":4,
        "strTema":"Portales",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Defina roles, jerarquias y grupos para ofrecer e integrar a su comunidad.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Comunicacion2020.09.14.10.17.37.png",
        "strImagenMouseOver":"Comu2020.09.14.10.17.37.png",
        "strAsignatura":"COMUNICACIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31927,
        "idAsignatura":4,
        "strTema":"Servicios financieros",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Configure multiples servicios e integre a sus ingresos.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Comunicacion2020.09.14.10.17.37.png",
        "strImagenMouseOver":"Comu2020.09.14.10.17.37.png",
        "strAsignatura":"COMUNICACIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31903,
        "idAsignatura":4,
        "strTema":"Voto electronico",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Legitimación del sistema democrático; el cual debe ser universal, secreto, directo, libre, personal e intransferible",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Comunicacion2020.09.14.10.17.37.png",
        "strImagenMouseOver":"Comu2020.09.14.10.17.37.png",
        "strAsignatura":"COMUNICACIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      }
    ],
    "8":[
      {
        "id":0,
        "idTema":1840,
        "idAsignatura":8,
        "strTema":"Alarmas",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Logre que sus datos le ayuden en la gestión.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Bi.Analitica2020.09.14.10.19.40.png",
        "strImagenMouseOver":"BIA2020.09.14.10.19.40.png",
        "strAsignatura":"BI",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":1852,
        "idAsignatura":8,
        "strTema":"Bi.educatic.com.co",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Inteligenica de negocio con la mejor herramienta.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Bi.Analitica2020.09.14.10.19.40.png",
        "strImagenMouseOver":"BIA2020.09.14.10.19.40.png",
        "strAsignatura":"BI",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":21871,
        "idAsignatura":8,
        "strTema":"Permanencia / prediccion",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Inteligencia artifical para la prediccion temprana.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Bi.Analitica2020.09.14.10.19.40.png",
        "strImagenMouseOver":"BIA2020.09.14.10.19.40.png",
        "strAsignatura":"BI",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31899,
        "idAsignatura":8,
        "strTema":"Reportes",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Modulo de consultas dinamicas.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Bi.Analitica2020.09.14.10.19.40.png",
        "strImagenMouseOver":"BIA2020.09.14.10.19.40.png",
        "strAsignatura":"BI",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31923,
        "idAsignatura":8,
        "strTema":"Snies",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Genere la informacion requerida por el MEN",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Bi.Analitica2020.09.14.10.19.40.png",
        "strImagenMouseOver":"BIA2020.09.14.10.19.40.png",
        "strAsignatura":"BI",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31900,
        "idAsignatura":8,
        "strTema":"Tableros",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Contruya sus propios tableros de BI.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Bi.Analitica2020.09.14.10.19.40.png",
        "strImagenMouseOver":"BIA2020.09.14.10.19.40.png",
        "strAsignatura":"BI",
        "blnPublicar":0,
        "idTipoAsignatura":0
      }
    ],
    "6":[
      {
        "id":0,
        "idTema":31921,
        "idAsignatura":6,
        "strTema":"Biblioteca virtual / bivi",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"OPAC(Online Public Access Catalog) ",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"GestionEstrategica2020.09.14.10.18.24.png",
        "strImagenMouseOver":"GE2020.09.14.10.18.24.png",
        "strAsignatura":"GESTIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":1847,
        "idAsignatura":6,
        "strTema":"Evaluación 360",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Herraminesta que permite a diversas personas evaluar diversas areas de forma simultanea.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"GestionEstrategica2020.09.14.10.18.24.png",
        "strImagenMouseOver":"GE2020.09.14.10.18.24.png",
        "strAsignatura":"GESTIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31904,
        "idAsignatura":6,
        "strTema":"Gestión de acuerdos",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Flujo de trabajo entre estudiantes, docentes y coordinadores sobre la ejecución de la asignatura.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"GestionEstrategica2020.09.14.10.18.24.png",
        "strImagenMouseOver":"GE2020.09.14.10.18.24.png",
        "strAsignatura":"GESTIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31896,
        "idAsignatura":6,
        "strTema":"Gestión de silabos",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Flujo de trabajo que propende mantener el curriculo actualizado.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"GestionEstrategica2020.09.14.10.18.24.png",
        "strImagenMouseOver":"GE2020.09.14.10.18.24.png",
        "strAsignatura":"GESTIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31912,
        "idAsignatura":6,
        "strTema":"Investigación / proyecta",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Permite el seguimiento de los Proyectos investigativos, monografías y tesis de una manera escalable y configurable ",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"GestionEstrategica2020.09.14.10.18.24.png",
        "strImagenMouseOver":"GE2020.09.14.10.18.24.png",
        "strAsignatura":"GESTIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31894,
        "idAsignatura":6,
        "strTema":"Proyectos de Extensión",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"IE puede conocer el rendimiento y la eficiencia en el uso de sus recursos físicos, financieros y el desempeño del recurso humano de manera colectiva e individual",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"GestionEstrategica2020.09.14.10.18.24.png",
        "strImagenMouseOver":"GE2020.09.14.10.18.24.png",
        "strAsignatura":"GESTIÓN",
        "blnPublicar":0,
        "idTipoAsignatura":0
      }
    ],
    "2":[
      {
        "id":0,
        "idTema":31887,
        "idAsignatura":2,
        "strTema":"Beneficios financieros",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Herraminesta que permite demogratizar la oferta de beneficios y su flujo de aprobación.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Oferta2020.09.14.10.16.25.png",
        "strImagenMouseOver":"O2020.09.14.10.16.25.png",
        "strAsignatura":"OFERTA",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31880,
        "idAsignatura":2,
        "strTema":"Calendarios / citas",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Defina el comportamiento y reglas en cuanto a las fechas, horarios y gestión de proceso.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Oferta2020.09.14.10.16.25.png",
        "strImagenMouseOver":"O2020.09.14.10.16.25.png",
        "strAsignatura":"OFERTA",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":24,
        "idAsignatura":2,
        "strTema":"Convenios / pagos masivos",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestión de convenios financieros con entidades publicas o privadas.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Oferta2020.09.14.10.16.25.png",
        "strImagenMouseOver":"O2020.09.14.10.16.25.png",
        "strAsignatura":"OFERTA",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":39,
        "idAsignatura":2,
        "strTema":"Extensión",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Completa gestión de los cursos de extensión, diplomados, pasantias, ferias, eventos.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Oferta2020.09.14.10.16.25.png",
        "strImagenMouseOver":"O2020.09.14.10.16.25.png",
        "strAsignatura":"OFERTA",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":1844,
        "idAsignatura":2,
        "strTema":"Financiaciones",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestion de la cartera IE.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Oferta2020.09.14.10.16.25.png",
        "strImagenMouseOver":"O2020.09.14.10.16.25.png",
        "strAsignatura":"OFERTA",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":21,
        "idAsignatura":2,
        "strTema":"Pre / matriculas / reajustes",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestion de la compra de los servicios académicos por parte de estudiantes.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Oferta2020.09.14.10.16.25.png",
        "strImagenMouseOver":"O2020.09.14.10.16.25.png",
        "strAsignatura":"OFERTA",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31881,
        "idAsignatura":2,
        "strTema":"Promociones",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Completa gestión de los cursos de extensión, diplomados, pasantias, ferias, eventos.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Oferta2020.09.14.10.16.25.png",
        "strImagenMouseOver":"O2020.09.14.10.16.25.png",
        "strAsignatura":"OFERTA",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31888,
        "idAsignatura":2,
        "strTema":"Tesoreria",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestión del Flujo de caja insitucional.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"Oferta2020.09.14.10.16.25.png",
        "strImagenMouseOver":"O2020.09.14.10.16.25.png",
        "strAsignatura":"OFERTA",
        "blnPublicar":0,
        "idTipoAsignatura":0
      }
    ],
    "1":[
      {
        "id":0,
        "idTema":19,
        "idAsignatura":1,
        "strTema":"Articulos",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Controle al gestión de sus activos fijos.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31892,
        "idAsignatura":1,
        "strTema":"Carteleras / lookfeel",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Cada IE Es unica, aproveche los diversos caneles para lograr un personalizacion",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":1841,
        "idAsignatura":1,
        "strTema":"Curricular",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Defina su modelo de comocimiento, Represente el SER Organizacional.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31877,
        "idAsignatura":1,
        "strTema":"Empleados",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestión de sus colaboradores y control de horarios.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31908,
        "idAsignatura":1,
        "strTema":"Espacios",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestione la disponibilidad y eficiencia en su infraestrcutura.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31878,
        "idAsignatura":1,
        "strTema":"Financiero",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestión de la causación y el recaudo.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31910,
        "idAsignatura":1,
        "strTema":"Integraciones",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Módulos de Migración y Auditoria de datos, conectores con diversos ERPs, LMS,y esquemas de autenticación.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31907,
        "idAsignatura":1,
        "strTema":"Politicas financieras",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Defina diversas estrategias de descuento para su comunidad de forma automatica.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31876,
        "idAsignatura":1,
        "strTema":"Prospección",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Generacion de estrategias de fidelizacion ya a nivel de colegios, region o individual.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":22,
        "idAsignatura":1,
        "strTema":"Reglamento",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Defina el comportamiento y reglas de diversas comunidades dentro de su IE.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31879,
        "idAsignatura":1,
        "strTema":"Selección",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestione diversos procesos de selección ya sean presencial o virtual e integre a procesos de mercadeo de forma automatica.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"BackOffice2020.09.14.10.15.50.png",
        "strImagenMouseOver":"BO2020.09.14.10.15.50.png",
        "strAsignatura":"BACK OFFICE",
        "blnPublicar":0,
        "idTipoAsignatura":0
      }
    ],
    "7":[
      {
        "id":0,
        "idTema":31875,
        "idAsignatura":7,
        "strTema":"Call center",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestion de Comunicados Carteleras y Vitrinas para los diversos perfiles. Módulos de visualización de campañas segmentados, diversificando la información a cualquier tipo de público, Administración de Noticias y publicaciones con rangos de tiempo.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"ServiciosMarketing2020.09.14.10.18.51.png",
        "strImagenMouseOver":"SM2020.09.14.10.18.51.png",
        "strAsignatura":"SERVICIOS",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":21874,
        "idAsignatura":7,
        "strTema":"Citas médicas / servicios",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestión de Calendarios, Agenda, Citas entre otros. ",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"ServiciosMarketing2020.09.14.10.18.51.png",
        "strImagenMouseOver":"SM2020.09.14.10.18.51.png",
        "strAsignatura":"SERVICIOS",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":21867,
        "idAsignatura":7,
        "strTema":"Contact center",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Desarrollo de una estrategia de marketing multicanal garantizando la respuesta todos sus contactos.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"ServiciosMarketing2020.09.14.10.18.51.png",
        "strImagenMouseOver":"SM2020.09.14.10.18.51.png",
        "strAsignatura":"SERVICIOS",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31882,
        "idAsignatura":7,
        "strTema":"Segmentacion ",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Seleccione conjunto de personas que cumplan con criterios establecidos ya sean Interesados, Formularios, Aspirantes, Estudiantes, Egresados.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"ServiciosMarketing2020.09.14.10.18.51.png",
        "strImagenMouseOver":"SM2020.09.14.10.18.51.png",
        "strAsignatura":"SERVICIOS",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31889,
        "idAsignatura":7,
        "strTema":"Soluciones",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Generación Estrategias Académicas, Institucionales, Socioeconomicas y/o Personales que son realizadas por las diversas areas.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"ServiciosMarketing2020.09.14.10.18.51.png",
        "strImagenMouseOver":"SM2020.09.14.10.18.51.png",
        "strAsignatura":"SERVICIOS",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31891,
        "idAsignatura":7,
        "strTema":"Talleres",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Genere invitacion a talleres, seminarios entre otros a partir del comportamiento académico de los estudiantes.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"ServiciosMarketing2020.09.14.10.18.51.png",
        "strImagenMouseOver":"SM2020.09.14.10.18.51.png",
        "strAsignatura":"SERVICIOS",
        "blnPublicar":0,
        "idTipoAsignatura":0
      }
    ],
    "5":[
      {
        "id":0,
        "idTema":31901,
        "idAsignatura":5,
        "strTema":"Autoevaluación",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Realice diagnóstico sobre sus fortalezas, debilidades y desafíos, estableciendo el nivel de cumplimiento de objetivos y metas",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"FlujosDeTrabajo2020.09.14.10.18.00.png",
        "strImagenMouseOver":"FT2020.09.14.10.18.00.png",
        "strAsignatura":"FLUJOS",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":1859,
        "idAsignatura":5,
        "strTema":"Bolsa de empleo",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Estrategia que promueva el relacionamiento entre el sector productivo, la comunidad educativa y el gobierno.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"FlujosDeTrabajo2020.09.14.10.18.00.png",
        "strImagenMouseOver":"FT2020.09.14.10.18.00.png",
        "strAsignatura":"FLUJOS",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31911,
        "idAsignatura":5,
        "strTema":"Documenta",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Mejore la eficiencia mediante la simplificación y automatización de sus procesos documentales.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"FlujosDeTrabajo2020.09.14.10.18.00.png",
        "strImagenMouseOver":"FT2020.09.14.10.18.00.png",
        "strAsignatura":"FLUJOS",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31898,
        "idAsignatura":5,
        "strTema":"Internacionalización",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Facilita el seguimiento y difusión de las acciones institucionales orientadas a la internacionalización en todos sus frentes",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"FlujosDeTrabajo2020.09.14.10.18.00.png",
        "strImagenMouseOver":"FT2020.09.14.10.18.00.png",
        "strAsignatura":"FLUJOS",
        "blnPublicar":0,
        "idTipoAsignatura":0
      }
    ],
    "3":[
      {
        "id":0,
        "idTema":31883,
        "idAsignatura":3,
        "strTema":"Academico / admisiones / egresado / retirados",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Completa gestión del acontecer académico",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"AcademicoCurricular2020.09.14.10.16.51.png",
        "strImagenMouseOver":"AC2020.09.14.10.16.51.png",
        "strAsignatura":"ACADÉMICO",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31886,
        "idAsignatura":3,
        "strTema":"Grupos / planeación",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Optimice la programación académica y control de recursos.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"AcademicoCurricular2020.09.14.10.16.51.png",
        "strImagenMouseOver":"AC2020.09.14.10.16.51.png",
        "strAsignatura":"ACADÉMICO",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":1834,
        "idAsignatura":3,
        "strTema":"Moodle",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Integrando su modelo de gestión a uno de los mejores LMS.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"AcademicoCurricular2020.09.14.10.16.51.png",
        "strImagenMouseOver":"AC2020.09.14.10.16.51.png",
        "strAsignatura":"ACADÉMICO",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":31895,
        "idAsignatura":3,
        "strTema":"Tablero de ausentismo",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Gestión de llaves y tablero de ausentismo docente.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"AcademicoCurricular2020.09.14.10.16.51.png",
        "strImagenMouseOver":"AC2020.09.14.10.16.51.png",
        "strAsignatura":"ACADÉMICO",
        "blnPublicar":0,
        "idTipoAsignatura":0
      },
      {
        "id":0,
        "idTema":1843,
        "idAsignatura":3,
        "strTema":"Tablero planeacion academica",
        "strDescripcionTema":"",
        "idEvaluacionTema":0,
        "idEvaluacion":0,
        "idTipoGrafico":0,
        "idDcto":0,
        "idContenidoTema":0,
        "strDescripcionContenidoTema":"Proyeccion de cupos, carga docente, espacios entre otros.",
        "intAnno":0,
        "intPeriodo":0,
        "idEstrategia":0,
        "idGrupo":0,
        "idVideo":0,
        "idMaterialTema":0,
        "idEmpresa":0,
        "strImagen":"AcademicoCurricular2020.09.14.10.16.51.png",
        "strImagenMouseOver":"AC2020.09.14.10.16.51.png",
        "strAsignatura":"ACADÉMICO",
        "blnPublicar":0,
        "idTipoAsignatura":0
      }
    ]
  }

export const benefits = [
  {
    id: 1,
    title: '100% en la nube',
    description: 'Educatic es una plataforma online, diseñada para universidades, por estar en la nube puedes acceder desde cualquier lugar y dispositivo.',
  },
  {
    id: 2,
    title: 'Seguridad',
    description: 'La información de tu universidad siempre protegida por los mejores sistemas de seguridad, hospedada en los servidores especializads de Amazon, no tienes que preocuparte por copias de seguridad o costosa infraestructura.',
  },
  {
    id: 3,
    title: 'Actualización en tiempo real',
    description: 'Cada vez que ingresas a tu plataforma, tienes acceso instantáneo a la versión más reciente de tu plataforma.',
  },
  {
    id: 4,
    title: 'Movilidad',
    description: 'Accede a tu información desde cualquier computador o dispositivo móvil con internet, también puedes descargar nuestra aplicación móvil para iOS y Android.',
  },
];

export const clients = [
  {
    id: 1,
    image: '/assets/images/tdea.jpg',
    alt: "logo Tecnológico de Antioquia"
  },
  {
    id: 2,
    image: '/assets/images/esumer.jpg',
    alt: "logo Esumer Institución Universitaria"
  },
  {
    id: 3,
    image: '/assets/images/eia.png',
    alt: "logo Universidad EIA"
  },
  {
    id: 4,
    image: '/assets/images/eafit.png',
    alt: "logo EAFIT"
  },
  {
    id: 5,
    image: '/assets/images/envigado.png',
    alt: "logo Alcaldía de Envigado"
  },
  {
    id: 6,
    image: '/assets/images/digital.png',
    alt: "Logo Institución Digital de Antioquia Programa de la Gobernación de Antioquia"
  }
];

export const header = {
  '/': {
    id: 1,
    images: [
      {
        id: 1,
        src: '/assets/images/banner.png',
        src_mobile: '/assets/images/banner_mobile.png',
        alt: 'Educatic es una solución completa para la educación superior a través de sus módulos de gestión',
      },
      {
        id: 1,
        src: '/assets/images/banner2.png',
        src_mobile: '/assets/images/banner_mobile.png',
        alt: 'Foto de estudiantes usando Educatic',
      },
      {
        id: 2,
        src: '/assets/images/banner3.png',
        src_mobile: '/assets/images/banner_mobile.png',
        alt: 'Educatic es una solución flexible y escalable para la administración de universidades',
      },
    ],
    title: 'Ahora tienes una solución predictiva',
    subtitle: 'Ahora la gestión es más fácil, nuestros módulos se adaptan a su medida.',
  },
  '/nosotros': {
    id: 2,
    images: [
      {
        id: 1,
        src: '/assets/images/banner.png',
        src_mobile: '/assets/images/banner_mobile.png',
        alt: 'Educatic es una solución completa para la educación superior a través de sus módulos de gestión',
      },
      {
        id: 1,
        src: '/assets/images/banner2.png',
        src_mobile: '/assets/images/banner_mobile.png',
        alt: 'Foto de estudiantes usando Educatic',
      },
      {
        id: 2,
        src: '/assets/images/banner3.png',
        src_mobile: '/assets/images/banner_mobile.png',
        alt: 'Educatic es una solución flexible y escalable para la administración de universidades',
      },
    ],
    title: 'Ahora tienes una solución predictiva',
    subtitle: 'Ahora la gestión es más fácil, nuestros módulos se adaptan a su medida.',
  },
  '/contacto': {
    id: 2,
    images: [
      {
        id: 1,
        src: '/assets/images/banner.png',
        src_mobile: '/assets/images/banner_mobile.png',
        alt: 'Educatic es una solución completa para la educación superior a través de sus módulos de gestión',
      },
      {
        id: 1,
        src_mobile: '/assets/images/banner_mobile.png',
        src: '/assets/images/banner2.png',
        alt: 'Foto de estudiantes usando Educatic',
      },
      {
        id: 2,
        src_mobile: '/assets/images/banner_mobile.png',
        src: '/assets/images/banner3.png',
        alt: 'Educatic es una solución flexible y escalable para la administración de universidades',
      },
    ],
    title: 'Una solución flexible, escalable y personalizada',
    subtitle: 'Ahora la gestión es más fácil, nuestros módulos se adaptan a su medida.',
  },
};

export const steps = [
  {
    id: 'welcome',
    message: 'Hola, soy Sarah, aún estoy en construcción. Pronto podré resolver tus inquietudes a cerca de la plataforma Educatic.',
    end: true,
  }
];

export const menu = [
  {
    id: 1,
    title: 'Inicio',
    link: '/'
  },
  {
    id: 2,
    title: 'Nosotros',
    link: '/nosotros'
  },
  {
    id: 3,
    title: 'Contáctenos',
    link: '/contacto'
  }
];

export const about = {
  title: 'Nuestra historia',
  description: `Mediante una serie de herramientas digitales, EDUCATIC concentra en un solo sistema todos los procesos administrativos, académicos, financieros, de mercadeo y sociales que requieren las instituciones educativas para reducir el tiempo de atención a los estudiantes y potencializar la eficiencia en todos los procesos.
  <br /><br />
  Además el sistema opera como una gran red social en la que interactúan estudiantes, docentes, padres de familia y TODOS los itegrantes de la comunidad educativa, de tal manera que se pueden monitorear los avances de los estudiantes y motivar a aquellos que estén rezagados en los procesos.`,
  numbers: [
    {
      id: 1,
      title: '100%',
      description: 'Respaldo en el proceso de acreditación',
    },
    {
      id: 2,
      title: '70%',
      description: 'Instituciones acreditadas',
    },
    {
      id: 3,
      title: '+10',
      description: 'Años en el mercado',
    },
    {
      id: 4,
      title: '+20.000',
      description: 'Usuarios recurrentes en nuestras apps',
    }
  ]
}
