import styled from 'styled-components';
import { Flex } from 'components/Grid';
import { hexToRGBA } from 'utils/styles';

const Container = styled(Flex)`
  position: fixed;
  z-index: 9; 
  top: 50%; 
  right: 0; 
  padding: ${props => props.theme.spaces[1]}px;
  padding-right: 30px;
  background: ${props => hexToRGBA(props.theme.colors.primary, 0.2)};
  border-bottom-left-radius: 10px;
  border-top-left-radius: 30px;

  & a {
    position: unset;
    box-shadow: none;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export default Container;
