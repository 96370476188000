import styled from 'styled-components';
import { Box as _Box } from 'grid-styled';
import { px } from 'utils/styles';

const Box = styled(_Box).attrs(props => ({
  mx: props.screen === "full" ? 0 : 'auto',
}))`
  z-index: ${props => props.index};
  background-color: ${props => props.background};

  ${props => props.screen !== "full" && `
    max-width: ${px(props.theme.breakpoints[2])};
  `}
`

export default Box;
