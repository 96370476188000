/**
 * action types
 */
const REQUEST_HEADER = 'REQUEST_HEADER';
const RECEIVE_HEADER = 'RECEIVE_HEADER';

const initialState = {
  loading: false,
  data: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_HEADER:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_HEADER:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    default:
      return state;
  }
}

function requestHeader() {
  return { type: REQUEST_HEADER };
}

function receiveHeader(data) {
  return { type: RECEIVE_HEADER, data };
}

export function fetchHeader() {
  return (dispatch) => {
    dispatch(requestHeader());

    const url = 'https://demo0278641.mockable.io/header';
    fetch(url)
      .then(response => response.json())
      .then((data) => {console.log(data)
        dispatch(receiveHeader(data));
      })
      .catch(() => {
        dispatch(receiveHeader(initialState.data));
      });
  };
}
