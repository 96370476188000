/**
 * action types
 */
const SET_THEME = 'SET_THEME';

const defaultState = {
  theme: 'light',
};

/**
 * platform reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default function modeReducer(state = defaultState, action = {}) {
  switch(action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: state.theme === 'light' ? 'dark' : 'light',
      };
    default:
      return state;
  }
}

/**
 * set platform theme mode
 * @returns {Object}
 */
export function changeTheme() {
  return { type: SET_THEME };
}
