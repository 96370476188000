import React from 'react';
import PropTypes from 'prop-types';
import Image from './index';

const loadingImage =
  'data:image/gif;base64,R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';

/**
 * render loading image and change src to data-src to lazyload carousel
 * @param {Object} props
 * @param {string} props.src original source of image
 * @param {string} props.className other classNames to be concatenated
 *
 */
function ImgLazy({ src, className, ...props }) {
  return (
    <Image
      className={`tns-lazy-img ${className}`}
      src={loadingImage}
      data-src={src}
      {...props}
    />
  );
}

ImgLazy.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['video', 'img', '360']),
  videoSrc: PropTypes.string,
};

ImgLazy.defaultProps = {
  className: '',
  type: 'img',
};

export default ImgLazy;
