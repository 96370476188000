import { STEPS } from 'utils/steps';

/**
 * action types
 */
const REQUEST_STEPS = 'REQUEST_STEPS';
const RECEIVE_STEPS = 'RECEIVE_STEPS';

const initialState = {
  loading: false,
  steps: [],
};

export default function stepsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_STEPS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_STEPS:
      return {
        ...state,
        loading: false,
        steps: action.data,
      };
    default:
      return state;
  }
}

function requestSteps() {
  return { type: REQUEST_STEPS };
}

function receiveSteps(data) {
  return { type: RECEIVE_STEPS, data };
}

export function fetchSteps() {
  return (dispatch) => {
    dispatch(requestSteps());
    setTimeout(() =>  dispatch(receiveSteps(STEPS)), 500);
  };
}
